import Guida from 'images2/home-inclusione/Guida.png';
import WeMiInclusionePdf from 'assets/PDF/Benvenuti_a_Milano_-_Italiano.pdf';

export const GUIDA_PER_MILANO = {
  description: 'Uno strumento per avere a portata \n di mano tutte le informazioni sui \n servizi messi a disposizione dai cittadini \n e le cittadine neo-arrivati a Milano',
  image: Guida,
  subTitle: 'GUIDA PER I NUOVI ARRIVATI',
  title: 'BENVENUTI A MILANO',
  buttonLabel: 'SCOPRI DI PIÙ',
  pdf: `${WeMiInclusionePdf}`,
};
