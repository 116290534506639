
import { useReducer, useRef, useEffect } from 'react';
import Dropin from 'braintree-web-drop-in';
import { isBackendError } from 'errors/api/definitions/BackendError';
import {
  INIT_ERROR,
  PAYMENT_METHOD_NONCE_ERROR,
  TRANSACTION_ERROR,
  UNMOUNTED_ERROR,
} from '../errors/ErrorShapes';
import {
  PaymentProviderClientError,
  isPaymentProviderError,
} from '../errors/PaymentProviderError';

/**
 * The action type to update the state of the payment provider.
 */
const UPDATE_PROVIDER_STATE = 'UPDATE_PROVIDER_STATE';

/**
 * The action creator to update the state of the payment provider.
 */
const updatePaymentProviderState = partial => ({
  type: UPDATE_PROVIDER_STATE,
  payload: partial,
});

/**
 * The reducer to update the state of the payment provider.
 */
const paymentProviderStateReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/**
 * A custom hook that exposes the payment provider
 * and takes care of its initialization.
 */
export const useBraintreePaymentProvider = (
  containerRef,
  paypalOptions,
  getTokenRequest,
  createPaymentTransactionRequest,
) => {
  // Embeds the loading state of the script.
  const [providerState, dispatch] = useReducer(paymentProviderStateReducer, {
    loaded: false,
    loading: false,
    instance: undefined,
    paymentRequestable: false,
    selectedPaymentMethod: undefined,
    paymentMethodOptioned: undefined,
    performingTransaction: false,
    purchaseCompleted: false,
  });

  // Tells if the component is mounted.
  const isMounted = useRef(true);

  /**
   * Keeps track of the mounting status of the component.
   */
  useEffect(
    () => {
      isMounted.current = true;

      // Destroy the provider instance when the component is unmounted.
      return () => {
        if (providerState.instance) {
          providerState.instance.teardown();
        }
        isMounted.current = false;
      };
    },
    []
  );

  /**
   * Optionally wraps the thrown error as an instance of PaymentProviderClientError.
   * @param {*} error The original error that was thrown.
   * @param {*} paymentProviderErrorShape The error shape used the build a PaymentProviderClientError.
   */
  const wrapProviderError = (originalError, paymentProviderErrorShape) => {
    // Propagates the error if generated by the backend API.
    if (isBackendError(originalError)) {
      return originalError;
    }

    // Otherwise the error is on the client side, so wraps it in a custom error.
    const { code, message } = paymentProviderErrorShape;
    return new PaymentProviderClientError(code, message, originalError);
  };

  /**
   * Initializes the payment provider.
   */
  const initialize = () => {
    // Set the provider status as loading.
    dispatch(updatePaymentProviderState({ loading: true }));

    return getTokenRequest()
      .then(token => {
        // Breaks the chain if the component has been unmounted.
        // NOTE: An error is thrown to skip next steps of the chain.
        if (!isMounted.current) {
          throw new PaymentProviderClientError(
            UNMOUNTED_ERROR.code,
            UNMOUNTED_ERROR.message
          );
        }

        return Dropin.create({
          authorization: token,
          container: containerRef.current,
          locale: 'it_IT',
          threeDSecure: true,
          translations: {
            chooseAnotherWayToPay: 'Scegli un altro metodo di pagamento',
            Card: 'Carta di credito',
            payWithCard: 'Paga con una carta di credito',
          },
          paypal: {
            flow: 'checkout',
            currency: 'EUR',
            ...paypalOptions,
          },
          dataCollector: true,
        });
      })
      .then(instance => {
        // Breaks the chain if the component has been unmounted.
        if (!isMounted.current) {
          throw new PaymentProviderClientError(
            UNMOUNTED_ERROR.code,
            UNMOUNTED_ERROR.message
          );
        }

        // The provider has been successfully loaded.
        dispatch(updatePaymentProviderState({
          loading: false,
          loaded: true,
          instance,
        }));

        instance.on('paymentOptionSelected', (payload) => dispatch(
          updatePaymentProviderState({ paymentMethodOptioned: payload.paymentOption })
        ));

        // Update payment validity when it changes.
        instance.on('paymentMethodRequestable', () => dispatch(
          updatePaymentProviderState({ paymentRequestable: true })
        ));

        instance.on('noPaymentMethodRequestable', () => dispatch(
          updatePaymentProviderState({
            paymentRequestable: false,
            selectedPaymentMethod: undefined,
          })
        ));
      })
      .catch(error => {
        // Do nothing if the error was triggered by the unmounting
        // of the component.
        if (isPaymentProviderError(error) && error.code === UNMOUNTED_ERROR.code) {
          return;
        }

        // Do nothing if the component was unmounted while initializing the provider.
        if (!isMounted.current) {
          return;
        }

        // Propagates the error.
        throw wrapProviderError(error, INIT_ERROR);
      });
  };

  /**
   * Requests a new payment method.
   */
  const requestPaymentMethod = async (threeDSecureParameters = {}) => {
    try {
      const paymentMethodNonce = await providerState.instance.requestPaymentMethod({
        threeDSecure: threeDSecureParameters,
      });


      // Error on validation 3DSecure
      if (!paymentMethodNonce.liabilityShifted && paymentMethodNonce.liabilityShiftPossible) {
        clearSelectedPaymentMethod();
        return;
      }

      dispatch(updatePaymentProviderState({ selectedPaymentMethod: paymentMethodNonce }));
    } catch (error) {
      // Propagates the error.
      throw wrapProviderError(error, PAYMENT_METHOD_NONCE_ERROR);
    }
  };

  /**
   * Processes the payment.
   */
  const processPayment = async payload => {
    try {
      dispatch(updatePaymentProviderState({ performingTransaction: true }));

      // Creates the transaction and updates internal state accordingly.
      const transactionResult = await createPaymentTransactionRequest(payload);

      dispatch(updatePaymentProviderState({
        performingTransaction: false,
        purchaseCompleted: true,
      }));
      return transactionResult;
    } catch (error) {
      dispatch(updatePaymentProviderState({ performingTransaction: false }));
      // Propagates the error.
      throw wrapProviderError(error, TRANSACTION_ERROR);
    }
  };

  /**
   * Clears the selected payment method.
   */
  const clearSelectedPaymentMethod = () => {
    providerState.instance.clearSelectedPaymentMethod();
    dispatch(updatePaymentProviderState({ selectedPaymentMethod: undefined }));
  };

  /**
   * Destroy dropin istance
   */
  const destroyDropin = () => {
    providerState.instance.teardown(() => dispatch(
      updatePaymentProviderState({ loaded: false,
        loading: false,
        instance: undefined,
        paymentRequestable: false,
        selectedPaymentMethod: undefined,
        paymentMethodOptioned: undefined,
        performingTransaction: false,
        purchaseCompleted: false })
    ));
  };

  return {
    ...providerState,
    initialize,
    requestPaymentMethod,
    processPayment,
    destroyDropin,
    clearSelectedPaymentMethod,
  };
};
