
/** @format */


  
  export const inserisciModificaDatiRichiesta004 =  [
      '',
     `mutation InserisciModificaAttributo ($input: AttributoInput!)
     {
        InserisciModificaAttributo(input: $input )
      }`
  ];

