import { PAGE_HOME_INCLUSIONE, PAGE_HOME_URL, PAGE_ORIENTAMENTO_SCOLASTICO_ED_EXTRA } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi inclusione',
    url: PAGE_HOME_INCLUSIONE,
  },
  {
    slash: 'ORIENTAMENTO SCOLASTICO ED EXTRASCOLASTICO',
    url: PAGE_ORIENTAMENTO_SCOLASTICO_ED_EXTRA,
  },
];
