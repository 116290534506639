/** @format */

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing.p2} 0;
  
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
