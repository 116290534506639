
export const ATTRIBUTI_REF_LAV = {
  preferenzaEta: 16,
  sessoLavoratore: 17,
  titoloStudioLavoratore: 18,
  esperienzaCon: 33,
  possessoAuto: 42,
  possessoPatente: 43,
  iscrizioneRegione: 53,
  istruzioneTata: 56,
  esperienzePregresse: 57,
  lingueParlate: 67,
  preferenzeCarattere: 68,
  anniEsperienza: 70,
  valutazioneEsperienza: 76,
  altrePreferenze: 93,
};
