
/** @format */


  export const inserisciModificaDatiRichiesta005 =  [
      '',
      `mutation InserisciModificaAttributo ($input: AttributoInput!)
      {
         InserisciModificaAttributo(input: $input )
       }`
  ];

