export const TCB = "TCB";
export const ENTE = "ENTE";

//stati richiesta base
export const RICHIESTA_BASE_BOZZA = "0";
export const RICHIESTA_BASE_APERTA = "1";
export const RICHIESTA_BASE_PAGATA = "2";
export const RICHIESTA_BASE_RIFIUTATA = "3";
export const RICHIESTA_BASE_SCADUTA = "4";
export const RICHIESTA_BASE_INOLTRATA = "5";
export const RICHIESTA_BASE_IN_GESTIONE = "6";
export const RICHIESTA_BASE_CHIUSA = "7";
export const RICHIESTA_BASE__TCB_RICHIESTA_ANNULLAMENTO = "7";
export const RICHIESTA_BASE_FINALIZZATA = "8";
export const RICHIESTA_BASE_PAGAMENTO_ANNULLATO = "10";

//stati richiesta ente

export const RICHIESTA_TCB_BOZZA = "0";
export const RICHIESTA_ENTE_INOLTRATA = "1";
export const RICHIESTA_ENTE_ACCETTATA = "2";
export const RICHIESTA_ENTE_CONVERSAZIONE = "3";
export const RICHIESTA_ENTE_ANNULATA = "4";
export const RICHIESTA_ENTE_CHIUSA = "5";
export const RICHIESTA_ENTE_SCADUTA = "6";
export const RICHIESTA_ENTE_PAGATA = "8";
export const RICHIESTA_TCB_INOLTRATA = "10";
export const RICHIESTA_TCB_GESTIONE = "11";
export const RICHIESTA_TCB_FINALIZZATA = "12";
export const RICHIESTA_TCB_CHIUSA = "13";
export const RICHIESTA_TCB_RICHIESTA_ANNULLAMENTO = "14";
