/** @format */

const HandleOrderTranslations = {
    en: {
        'HandleOrder.header.title': 'Handle ',
        'HandleOrder.header.titleBold': 'Order',
        'HandleOrder.header.breadcrumb': "Handle Order",    

  
    },
    it: {
        'HandleOrder.header.title': 'Gestione ',
        'HandleOrder.header.titleBold': "Ordine",
        'HandleOrder.header.breadcrumb': "Gestione ordine",
    },
  };
  
  export default HandleOrderTranslations;
  