export const limitsValue = {
  min: 0,
  max: 5,
};

export const content = [
  {
    text: 'corpo e movimento',
    key: 'corpoMovimento',
  },
  {
    text: 'relazioni',
    key: 'relazioni',
  },
  {
    text: 'conoscenze e competenze',
    key: 'conoscereCompetenze',
  },
  {
    text: 'creatività, abilità e talenti',
    key: 'creativitaAbilitaTalento',
  },
  {
    text: 'autodeterminazione',
    key: 'autodeterminazione',
  },
];
