export const feedbackState = [
  {
    id: 0,
    value: "Tutti",
  },
  {
    id: 4,
    value: "Non Richiesto",
  },
  {
    id: 1,
    value: "Richiesto",
  },
  {
    id: 2,
    value: "Rilasciato",
  },
  {
    id: 3,
    value: "Confermato",
  },
];

export const requestStates = [
  {
    id: 0,
    value: "Tutti gli stati",
  },
  {
    id: 1,
    value: "Inoltrata",
  },
  {
    id: 2,
    value: "Accettata",
  },
  {
    id: 3,
    value: "Conversazione",
  },
  {
    id: 4,
    value: "Annullata dal cittadino",
  },
  {
    id: 5,
    value: "Chiusa dall'ente",
  },
  {
    id: 6,
    value: "Scaduta",
  },
  {
    id: 8,
    value: "Pagata",
  },
  {
    id: 15,
    value: "pagamento annullato da admin",
  },
];

export const serviceTipology = [
  {
    id: 0,
    value: "Tutte le tipologie",
  },
  {
    id: 1,
    value: "Individuale",
  },
  {
    id: 2,
    value: "Condiviso",
  },
];

export const PAGINATION_REQUEST_ENTE = 10;
