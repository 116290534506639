/** @format */

export const FooterJson = {
  columnLeft: {
    title: 'Comune',
    links: [
      'Footer.link.municipality1',
      'Footer.link.municipality2',
      'Footer.link.municipality3',
      'Footer.link.municipality4',
      'Footer.link.municipality5',
      'Footer.link.municipality6',
      'Footer.link.municipality7',
      'Footer.link.municipality8',
      'Footer.link.municipality9',
    ],
  },
  columnRight: {
    title: 'Link utili',
    links: [
      'Footer.usefulLink.link1',
      'Footer.usefulLink.link2',
      'Footer.usefulLink.link3',
      'Footer.usefulLink.link4',
    ],
    contacts: ['Piazza della Scala, 2 - 20121 Milano Italia', ' Codice fiscale/Partita IVA 01199250158', 'Centralino unico 020202'],
  },
};
