/** @format */

const RequestServiceTranslations = {
  en: {
    'RequestService.header.title': 'service request ',
    'RequestService.header.titleBold': "men's hairdresser",
    'RequestService.completeRequest.title': 'Complete ',
    'RequestService.riepilogo.title': 'Summary ',
    'RequestService.completeRequest.titleBold': 'your request',
    'RequestService.completeRequest.third.label': 'Quantity',
    'RequestService.completeRequest.third.input': 'number person',
    'RequestService.completeRequest.description':
      'Continue in your request leaving the detailed information requested.',
    'RequestService.completeRequest.first.label': 'Period',
    'RequestService.completeRequest.first.dateLabel': 'since the day',
    'RequestService.completeRequest.first.dateLabel1': 'per day',
    'RequestService.completeRequest.second.label': 'Note',
    'RequestService.completeRequest.second.button': 'Send request',
    'RequestService.completeRequest.second.textArea': 'your message',
    'RequestService.riepilogo.first.title': 'Tasks',
    'RequestService.riepilogo.first.title1': 'Rating',
    'RequestService.riepilogo.second.title': 'Selected Entities',
    'RequestService.riepilogo.enti.link': 'Ent Card',
    'RequestService.riepilogo.enti.linkRed': 'Delete',
    'RequestService.riepilogo.riepilogoarr.label': 'Where',
    'RequestService.riepilogo.riepilogoarr.label1': 'Price',
    'RequestService.riepilogo.riepilogoarr.label2': 'Schedule',
    'RequestService.riepilogo.riepilogoarr.value': 'Name of the indicated area',
    'RequestService.riepilogo.riepilogoarr.value1': 'For a cost not exceeding: ',
    'RequestService.riepilogo.riepilogoarr.value1Bold': '€12,00',
    'RequestService.riepilogo.riepilogoarr.value2': 'Service to be provided ',
    'RequestService.riepilogo.riepilogoarr.value2Bold': 'in the afternoon',
    'RequestService.completeRequest.third.input1': 'number of services',
    'RequestService.completeRequest.third.input2': 'number of person',
    'RequestService.completeRequest.switch.title': 'Request availability (yes/no) ',
    'RequestService.riepilogo.enti.labelLeft' : 'Accept custom quotes',
    'RequestService.riepilogo.enti.labelCenterTitle': 'from ',
    'RequestService.riepilogo.noData': 'No filter selected',
    'RequestService.riepilogo.afterPrice': ' for a total of ',
     'RequestService.riepilogo.beforePrice': 'not more than ',
     'RequestService.riepilogo.afterPrestazioni': ' performance'
  },
  it: {
    'RequestService.header.title': 'RICHIESTA DEL SERVIZIO ',
    'RequestService.header.titleBold': 'PARRUCCHIERE UOMO',
    'RequestService.completeRequest.third.label': 'Quantità',
    'RequestService.completeRequest.third.input1': 'numero di prestazioni',
    'RequestService.completeRequest.third.input2': 'numero di persone',
    'RequestService.completeRequest.title': 'Completa ',
    'RequestService.completeRequest.switch.title': 'Richiedi disponibilità (si/no) ',
    'RequestService.riepilogo.title': 'Riepilogo ',
    'RequestService.completeRequest.titleBold': 'la tua richiesta',
    'RequestService.completeRequest.description':
      'Compila i dettagli della tua richiesta.',
    'RequestService.completeRequest.first.label': 'Periodo',
    'RequestService.completeRequest.first.dateLabel': 'dal giorno',
    'RequestService.completeRequest.first.dateLabel1': 'al giorno',
    'RequestService.completeRequest.second.label': 'Note',
    'RequestService.completeRequest.second.button': 'Invia richiesta',
    'RequestService.completeRequest.second.textArea': 'il tuo messaggio',
    'RequestService.riepilogo.first.title': 'Mansioni',
    'RequestService.riepilogo.first.title1': 'Rating',
    'RequestService.riepilogo.second.title': 'Enti Selezionati',
    'RequestService.riepilogo.riepilogoarr.label': 'Dove',
    'RequestService.riepilogo.riepilogoarr.label1': 'Prezzo',
    'RequestService.riepilogo.riepilogoarr.label2': 'Orario',
    'RequestService.riepilogo.riepilogoarr.value': 'Nome della zona indicata',
    'RequestService.riepilogo.riepilogoarr.value1': 'Per un costo non superiore a: ',
    'RequestService.riepilogo.riepilogoarr.value1Bold': '€12,00',
    'RequestService.riepilogo.riepilogoarr.value2': 'Servizio da erogare ',
    'RequestService.riepilogo.riepilogoarr.value2Bold': 'nel pomeriggio',
    'RequestService.riepilogo.enti.link': 'Scheda ente',
    'RequestService.riepilogo.enti.linkRed': 'Rimuovi',
    'RequestService.riepilogo.enti.labelLeft' : 'Accetta preventivi personalizzati',
    'RequestService.riepilogo.enti.labelCenterTitle': 'a partire da ',
    'RequestService.riepilogo.noData': 'Nessuna opzione è stata selezionata',
    'RequestService.riepilogo.afterPrice': ' per un totale di ',
     'RequestService.riepilogo.beforePrice': 'non superiore a ',
     'RequestService.riepilogo.afterPrestazioni': ' prestazioni'
  },
};

export default RequestServiceTranslations;
