import { PAGE_HOME_URL, PAGE_EDUCAZIONE_FINANZIARIA } from 'types/url';

export const BreadcrumbPath = Object.freeze([
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi Educazione Finanziaria',
    url: PAGE_EDUCAZIONE_FINANZIARIA,
  },
  {
    slash: 'PENSIONE',
    url: '',
  },
]);
