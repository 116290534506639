import React from 'react';
import { Row } from 'components/ui/Grid';
import { OtherServicesButton } from './partials';

const OtherServicesNavigation = ({ data, isLoading }) =>
   (
     <Row fluid>
       <OtherServicesButton
         loading={isLoading}
         data={data}
       />
     </Row>
  );
OtherServicesNavigation.displayName = 'OtherServicesNavigation';
export default OtherServicesNavigation;
