
/**
 * The type of default navigation steps.
 */
export const NAVIGATION_STEPS_TYPE_CODE = {
  beneficiario: 'beneficiario',
  mansioni: 'mansioni',
  casa: 'casa',
  animali: 'animali',
  disponibilita: 'disponibilita',
  preferenzelav: 'preferenzelav',
  sedelavoro: 'sedelavoro',
};
