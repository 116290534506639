import { PAGE_HOME_URL, PAGE_AREAPERSONALE_URL, PAGE_GESTIONE_UTENZE } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'Area personale',
    url: PAGE_AREAPERSONALE_URL,
  },
  {
    slash: 'GESTIONE UTENZE',
    url: PAGE_GESTIONE_UTENZE,
  },

];