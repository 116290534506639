export const listaPartner = [
  {
    nome: 'Cooperativa Amelinc',
    link: 'http://www.amelinc.org/',
  },
  {
    nome: 'Associazione Arcobaleno',
    link: 'http://www.associazione-arcobaleno.org/',
  },
  {
    nome: 'Associazione Asnada',
    link: 'http://www.asnada.it/',
  },
  {
    nome: 'Associazione Compagnia Africana',
    link: 'http://www.compagniafricana.org/',
  },
  {
    nome: 'Cooperativa Diapason',
    link: 'https://www.coopdiapason.it/',
  },
  {
    nome: 'Cooperativa Farsi Prossimo',
    link: 'https://farsiprossimo.it/',
  },
  {
    nome: 'Fondazione Franco Verga C.O.I.',
    link: 'https://www.fondazioneverga.org/',
  },
  {
    nome: 'Fondazione ISMU',
    link: 'https://www.ismu.org/',
  },
  {
    nome: 'Associazione Mamme a Scuola',
    link: 'http://mammeascuola.it/',
  },
  {
    nome: 'Fondazione Monserrate',
    link: 'http://www.monserrate.org/',
  },
  {
    nome: 'Cooperativa Piccolo Principe',
    link: 'http://www.piccoloprincipeonlus.org/',
  },
  {
    nome: 'Cooperativa Progetto Integrazione',
    link: 'http://www.progettointegrazione.it/',
  },
  {
    nome: 'Cooperativa Tempo per l’infanzia',
    link: 'http://www.tempoperlinfanzia.it/',
  },
  {
    nome: 'Associazione Villa Pallavicini',
    link: 'http://www.villapallavicini.org/',
  },
];
