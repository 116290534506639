/** @format */

const SwitchLocaleTranslations = {
  en: {
    'Locale.link.en': 'English',
    'Locale.link.it': 'Italian',
  },
  it: {
    'Locale.link.en': 'Inglese',
    'Locale.link.it': 'Italiano',
  },
};

export default SwitchLocaleTranslations;
