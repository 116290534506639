/** @format */

const MessageTranslations = {
  en: {
    'Message.component.crashed': 'Ops! Something went wrong :(',
  },
  it: {
    'Message.component.crashed': 'Ops! Qualcosa è andato storto :(',
  },
};

export default MessageTranslations;
