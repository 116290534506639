/** @format */

const SearchPageTranslations = {
  en: {
    'SearchPageTranslations.title': 'Search',
  },

  it: {
    'SearchPageTranslations.title': 'Ricerca',
  },
};

export default SearchPageTranslations;
