/** @format */

import React from 'react';
import AccordionSchedaOp from 'components/navigation/EntSchedaOp/AccordionSchedaOp';
const SchedaOp = () => (
  <div>
    <AccordionSchedaOp />
  </div>
);

SchedaOp.displayName = 'SchedaOp';

export default SchedaOp;
