import { cdAttributo } from "../../CodiciAttributi";

export const arrayAttributi = [
  cdAttributo.CD_TIPOLOGIA_ASSUNZIONE,
  cdAttributo.CD_ORARIO_LAVORO,
  cdAttributo.CD_LIVELLO_CONTRATTUALE,
  cdAttributo.FG_DISPONIBILITA_VACANZA_SOLO_CON_ASSISTITO,
  cdAttributo.LS_SPAZI_CONVIVENTE,
  cdAttributo.LS_MEZZA_GIORNATA_CONVIVENTE,
  cdAttributo.DT_DATA_CONTRATTO_DA,
  cdAttributo.DT_DATA_CONTRATTO_A,
  cdAttributo.NR_ORE_RICHIESTE,
  cdAttributo.IM_STIPENDIO_CONVIVENTE,
  cdAttributo.IM_STIPENDIO_A_CONVIVENZA_RIDOTTA,
  cdAttributo.IM_STIPENDIO_NON_CONVIVENTE,
  cdAttributo.IM_STIPENDIO_WEEKEND,
  cdAttributo.IM_STIPENDIO_ASSISTENZA_NOTTURNA,
  cdAttributo.IM_STIPENDIO_PRESENZA_NOTTURNA,
  cdAttributo.CD_TIPOLOGIA_CONTRATTO,
  cdAttributo.FG_DISP_TRASFERTE_BREVI,
  cdAttributo.FG_DISP_TRASFERTE_LUNGHE,
  cdAttributo.FG_DISPONIBILITA_STRAORDINARI,
  cdAttributo.FG_DISPONIBILITA_VACANZA_CON_FAMIGLIA,
  cdAttributo.CD_FASCIA_ORE_SETTIMANALI,
  cdAttributo.CALENDARIO_CONVIVENZA_RIDOTTA,
  cdAttributo.CALENDARIO_NON_CONVIVENTE,
  cdAttributo.CALENDARIO_PRESENZA_NOTTURNA,
  cdAttributo.CALENDARIO_ASSISTENZA_NOTTURNA,
  cdAttributo.TX_NOTE_SU_CONTRATTO,
  cdAttributo.FL_PIU_PERSONE_NON_AUTOSUFFICIENTI
];
