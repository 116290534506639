import React from 'react';
import EducazioneFinanziariaNavigation from 'components/navigation/EducazioneFinanziaria';

const HomePageEducazioneFinanziaria = () => (
  <>
    <EducazioneFinanziariaNavigation />
  </>
  );

HomePageEducazioneFinanziaria.displayName = 'HomePageEducazioneFinanziaria';


export default HomePageEducazioneFinanziaria;
