export const AUTHORIZATIONS = {
  CONTENT_MANAGEMENT: 1,
  USER_MANAGEMENT: 2,
  CORPORATE_DATA_MANAGEMENT: 3,
  CORPORATE_REQUEST_SERVICES_VISUALIZATION: 4,
  APPLICATION_TCB_MANAGEMENT: 5,
  NOMINATIONS_TCB_MANAGEMENT: 6,
  CORPORATE_DATA_VISUALIZATION: 7,
  VOUCHER_MANAGEMENT: 8,
};
