import React from 'react';
import Text from 'components/ui/Text';

export const subTitle = (
  <Text
    lineHeight="175%"
    size="f7"
    whitespace="pre-line"
    value={'Per costruire percorsi di inclusione\ndelle persone centrati sulla promozione\ndei diritti.'}
  />
);
