import { PAGE_AREAPERSONALE_URL, PAGE_GESTIONE_UTENZE, PAGE_MODIFICA_UTENZA } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Area personale',
    url: PAGE_AREAPERSONALE_URL,
  },
  {
    slash: 'Gestione utenze',
    url: PAGE_GESTIONE_UTENZE,
  },
  {
    slash: 'MODIFICA UTENZA',
    url: PAGE_MODIFICA_UTENZA,
  },

];