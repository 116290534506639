import React from 'react';
import Text from 'components/ui/Text';

export const subTitle = (
  <Text
    lineHeight="175%"
    size="f7"
    whitespace="pre-line"
    value={'Per informare sui corsi di italiano L2\n presenti in città e orientare\nalla scelta del percorso più adatto.'}
  />
  );
