export const serviziTCB = [
  {
    title: 'ORIENTAMENTO E CONSULENZA',
    value: "Per affrontare questioni legate alla relazione con l'assistito e la sua famiglia o alla gestione del rapporto di lavoro grazie alla mediazione di un nostro operatore. Per conoscere meglio il servizio di assistenza familiare, il contratto di lavoro domestico o le opportunità di formazione professionale per baby-sitter, colf o badanti.",
  },
  // {
  //   title: 'TUTORING',
  //   value: "Per affrontare questioni legate alla relazione con l'assistito e la sua famiglia o alla gestione del rapporto di lavoro grazie alla mediazione di un nostro operatore, presso il tuo domicilio o presso la sede di WeMi Tate Colf Badanti.",
  // },
  {
    title: 'REGISTRO TERRITORIALE DEGLI ASSISTENTI FAMILIARI DI REGIONE LOMBARDIA',
    value: 'Per iscriverti al Registro Territoriale degli Assistenti familiari istituito da Regione Lombardia.',
  },
];
