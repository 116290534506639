import { PAGE_HOME_INCLUSIONE, PAGE_HOME_URL, PAGE_INCLUSIONE_RICONGIUNGIMENTO } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi inclusione',
    url: PAGE_HOME_INCLUSIONE,
  },
  {
    slash: 'RICONGIUNGIMENTO FAMILIARE',
    url: PAGE_INCLUSIONE_RICONGIUNGIMENTO,
  },
];
