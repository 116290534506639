/** @format */

const RequestsIndexTranslations = {
  en: {
    'RequestsIndex.header.title': 'Index of ',
    'RequestsIndex.header.titleBold': "requests and bought services",
    'RequestsIndex.header.breadcrumb': "Index of requests and bought services",

  },
  it: {
    'RequestsIndex.header.title': 'Indice ',
    'RequestsIndex.header.titleBold': 'Richieste e servizi acquistati',
    'RequestsIndex.header.breadcrumb': "Indice Richieste e servizi acquistati",

    
  },
};

export default RequestsIndexTranslations;
