import { PAGE_HOME_URL, PAGE_AREAPERSONALE_URL, PAGE_GESTIONE_VOUCHER, PAGE_LISTA_TRANSAZIONI } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'Area personale',
    url: PAGE_AREAPERSONALE_URL,
  },
  {
    slash: 'Gestione Voucher',
    url: PAGE_GESTIONE_VOUCHER,
  },
  {
    slash: 'Lista Transazioni',
    url: PAGE_LISTA_TRANSAZIONI,
  },

];
