import Img1 from "images2/comefunziona-018/Come-funziona-0-18_1.png";
import Img2 from "images2/comefunziona-018/Come-funziona-0-18_2.png";
import Img3 from "images2/comefunziona-018/Come-funziona-0-18_3.png";
import Img4 from "images2/comefunziona-018/Come-funziona-0-18_4.png";
import Img5 from "images2/comefunziona-018/Come-funziona-0-18_5.png";

export const stepList = Object.freeze([
  {
    title: "categorie",
    img: Img1,
    textbold: "Seleziona la categoria di servizi o attività ",
    text2: "di tuo interesse",
    padding: {
      md: "0 1.5em 0 1.5em",
    },
    maxWidth: {
      xs: "11.67rem",
      md: "9rem",
      lg: "10.93rem",
    },
  },
  {
    title: "servizi",
    img: Img2,
    textbold: "Scegli un servizio o un'attività e personalizzali ",
    text2: "attraverso i filtri a disposizione",
    padding: {
      md: "0 0.5em 0 0.5em",
    },
    maxWidth: {
      xs: "11.67rem",
      md: "9rem",
      lg: "10.93rem",
    },
  },
  {
    title: "richieste",
    img: Img3,
    text1: "Seleziona la proposta di uno o più enti e ",
    textbold: "invia la richiesta ",
    text2: "accedendo tramite SPID",
    padding: {
      md: "0 1em 0 1.5em",
    },
    maxWidth: {
      xs: "11.67rem",
      md: "9rem",
      lg: "10.93rem",
    },
  },
  {
    title: "acquisti",
    img: Img4,
    text1: "Attendi la risposta e ",
    textbold: "acquista direttamente online",
    text2:
      ". N.B.: Si ricorda che i voucher Milano 0-18 sono scaduti il 31/07/2023 e, pertanto, non sono più utilizzabili.",
    padding: {
      md: "0",
    },
    maxWidth: {
      xs: "11.67rem",
      md: "9rem",
      lg: "10.93rem",
    },
  },
  {
    title: "recensioni",
    img: Img5,
    textbold: "Esprimi il tuo livello di gradimento ",
    text2: "sul servizio o sull'attività che hai acquistato",
    padding: {
      md: "0 0.7em 0 1.5em",
    },
    maxWidth: {
      xs: "11.67rem",
      md: "9rem",
      lg: "10.93rem",
    },
  },
]);
