
export const DEFAULT_ACTION_BUTTONS = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'emoji',
  'image',
  'remove',
  'history',
];