import { PAGE_INCLUSIONE_CONOSCERE_INTEGRARSI, PAGE_HOME_INCLUSIONE, PAGE_INCLUSIONE_APPRENDIMENTO_ITALIANO, PAGE_HOME_URL } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi inclusione',
    url: PAGE_HOME_INCLUSIONE,
  },
  {
    slash: 'Apprendimento della lingua italiana',
    url: PAGE_INCLUSIONE_APPRENDIMENTO_ITALIANO,
  },
  {
    slash: 'FAMI CONOSCERE PER INTEGRARSI',
    url: PAGE_INCLUSIONE_CONOSCERE_INTEGRARSI,
  },

];
