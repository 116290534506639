/** @format */

const OrderSummaryTranslations = {
    en: {
        'OrderSummary.header.title': 'Order ',
        'OrderSummary.header.titleBold': 'Summary',
        'OrderSummary.header.breadcrumb': "Order Summary",

    },
    it: {
        'OrderSummary.header.title': 'Riepilogo ',
        'OrderSummary.header.titleBold': "Ordine",
        'OrderSummary.header.breadcrumb': "Riepilogo ordine",
    },
};

export default OrderSummaryTranslations;
