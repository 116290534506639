/** @format */
  
export const EstraiServiziPerCategoriediAccredidatmentoEnte = (id) => [
    '',
    `{
      EstraiServiziPerCategoriediAccredidatmentoEnte(id_ente:${id}){
        value
        textValue
        catAccreditamento
      }
    }`,
  ];
  