import { LUNEDI, MARTEDI, MERCOLEDI, GIOVEDI, VENERDI, SABATO, DOMENICA } from 'components/ui2/WeekCalendarTimePicker/utils/constants'

export const defaultCalendar = {
  [LUNEDI.value]: [],
  [MARTEDI.value]: [],
  [MERCOLEDI.value]: [],
  [GIOVEDI.value]: [],
  [VENERDI.value]: [],
  [SABATO.value]: [],
  [DOMENICA.value]: [],
};
