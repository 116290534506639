import React from 'react';
import Text from 'components/ui/Text';

export const subTitle = (
  <Text
    lineHeight="175%"
    size="f7"
    whitespace="pre-line"
    value={'Per orientare i ragazzi e le ragazze\nalla scelta del percorso scolastico e delle\nattività formative e del tempo libero.'}
  />
  );
