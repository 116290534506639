/**
 * The label to display when selected the filter
 */
export const labelCheckboxListDisponibilita = {
  vacanzaFamiglia: 'Vacanza Famiglia',
  trasferteBrevi: 'Trasferte Brevi',
  vacanzaAssistito: 'Vacanza Assistito',
  trasferteLunghe: 'Trasferte Lunghe',
  lavorareFuoriMilano: 'Lavoro Fuori MI',
  straordinari: 'Straordinari',

};
