export const NUMBER_ITEMS_TABLE = 10;

export const columnsTable = [
  'CF MINORE',
  'IMPORTO TRANSAZIONE VOUCHER',
  'STATO TRANSAZIONE',
  'DATA PAGAMENTO',
  'SERVIZIO ACQUISTATO',
  'ENTE',
  'IDENTIFICATIVO DELLE RICHIESTA SERVIZIO ENTE',
  'AZIONI',
];
