import React from 'react';
import Text from 'components/ui/Text';

export const subTitle = (
  <Text
    lineHeight="175%"
    size="f7"
    whitespace="pre-line"
    value={'Per supportare cittadini e cittadine\nneo-arrivati e quelli che vogliono\nricongiungere a Milano i loro familiari.'}
  />
  );
