import { AUTHORIZATIONS } from './authorizationsList';

const AUTHORIZATIONS_CONTENT_MANAGEMENT = [
  AUTHORIZATIONS.CONTENT_MANAGEMENT
];

const AUTHORIZATIONS_CORPORATE_MANAGEMENT = [
  AUTHORIZATIONS.CORPORATE_DATA_MANAGEMENT,
  AUTHORIZATIONS.CORPORATE_DATA_VISUALIZATION
];

const AUTHORIZATIONS_CORPORATE_REQUEST_SERVICES = [
  AUTHORIZATIONS.CORPORATE_REQUEST_SERVICES_VISUALIZATION
];

const AUTHORIZATIONS_TCB_CANDIDACY_MANAGEMENT = [
  AUTHORIZATIONS.APPLICATION_TCB_MANAGEMENT
];

const AUTHORIZATIONS_TCB_QUESTION_MANAGEMENT = [
  AUTHORIZATIONS.APPLICATION_TCB_MANAGEMENT
];

const AUTHORIZATIONS_USER_MANAGEMENT= [
  AUTHORIZATIONS.USER_MANAGEMENT
];

const AUTHORIZATIONS_VOUCHER_MANAGEMENT= [
  AUTHORIZATIONS.VOUCHER_MANAGEMENT
];

const AUTHORIZATIONS_TCB_CANDIDACY_MANAGEMENT_AND_QUESTION_MANAGEMENT = AUTHORIZATIONS_TCB_QUESTION_MANAGEMENT.concat(AUTHORIZATIONS_TCB_CANDIDACY_MANAGEMENT);

export const UNDER_AUTHORIZATIONS_LIST = {
  AUTHORIZATIONS_CONTENT_MANAGEMENT,
  AUTHORIZATIONS_CORPORATE_MANAGEMENT,
  AUTHORIZATIONS_CORPORATE_REQUEST_SERVICES,
  AUTHORIZATIONS_TCB_CANDIDACY_MANAGEMENT,
  AUTHORIZATIONS_TCB_QUESTION_MANAGEMENT,
  AUTHORIZATIONS_USER_MANAGEMENT,
  AUTHORIZATIONS_TCB_CANDIDACY_MANAGEMENT_AND_QUESTION_MANAGEMENT,
  AUTHORIZATIONS_VOUCHER_MANAGEMENT,
};
