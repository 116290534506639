/**
 * The label to display when selected the filter
 */
export const labelCheckboxListDatiPersonali = {
  patente: 'Patente',
  automunito: 'Automunito',
  cani: 'Cani',
  gatti: 'Gatti',
  lavoratorePresenzaAnimali: 'Animali',
  disponibilitaAnimali: 'Disponibilità Animali',
};
