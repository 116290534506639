import { PAGE_HOME_URL, PAGE_AREAPERSONALE_URL, PAGE_ELENCO_VOUCHER } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'Area personale',
    url: PAGE_AREAPERSONALE_URL,
  },
  {
    slash: 'ELENCO VOUCHER',
    url: PAGE_ELENCO_VOUCHER,
  },

];
