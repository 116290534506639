import styled from 'styled-components';

export const HeaderImageDivCaption = styled.div`
  letter-spacing: 0.05em;
  padding: 0 0.05em;
  height: 100%;
  width: 100%;
`;

export const HeaderImageDiv = styled.div`
  height: 100%;
  width: 100%;
`;
