/** @format */

import { Row } from 'components/ui/Grid';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

Wrapper.displayName = '   Wrapper';
export default Wrapper;
