export const estraiListaSlider = [
  '',
  `query getAllListSlider {
        getAllListSlider {
         list {   
         id
         title
         description
         image {
             id
             path
             name
         }   
        }
    }
    }`,
  'getAllListSlider',
];
