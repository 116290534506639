/**
 * GraphQL payment errors.
 */

export const INTERNAL_SERVER_ERROR = 0;

// Payment errors.
export const PAYMENT_PROVIDER_TOKEN_ERROR = 1004;
export const PAYMENT_PROVIDER_FAILED_TRANSACTION = 1005;
export const PAYMENT_PROVIDER_NOT_PURCHASABLE_REQUEST = 1006;
export const PAYMENT_PROVIDER_FAILED_DB_TRACKING_SUCCESSFULL_TRANSACTION = 2011;
export const PAYMENT_PROVIDER_FAILED_DB_TRACKING_FAILED_TRANSACTION = 2012;

// Authorization errors
export const UNAUTHORIZED = 1200;
export const ACCESS_DENIED = 1201;
export const UNAUTHENTICATED = 1202;
export const UTENTE_NON_VALIDO = 1212;
export const UTENTE_NON_RICONOSCIUTO = 1213;

// Scheda ente
export const INSERT_OPERATORE_VALIDATION_ERROR = 2001;
export const INSERT_OPERATORE_ADMIN_VALIDATION_ERROR = 2002;
export const DELETE_OPERATORE_ASSOCIATO_VALIDATION_ERROR = 2003;
export const INSERT_OPERATORE_WEMI_ADMIN_VALIDATION_ERROR = 2014;
export const INSERT_LAVORATORE_VALIDATION_ERROR = 2015;
export const GET_RICHIESTE_ENTE_ERROR = 2005;
export const COUNT_RICHIESTE_ENTE_ERROR = 2006;
export const COUNT_FEEDBACK_ENTE_ERROR = 2007;
export const DELETE_SECONDARY_OFFICE_VALIDATION_ERROR = 2008;


// Backoffice TCB
export const WORKER_EVALUATION_REQUEST_BACKOFFICE_TCB = 1019;

// Errori generali
export const GENERATE_PDF_ERROR = 1017;
export const SEND_EMAIL_ERROR = 1014;
export const VALIDATION_ERROR = 1015;
export const MULTIPLE_VALIDATION_ERROR = 1016;
export const NOT_BLOCKING_SEND_EMAIL_ERROR = 1018;
export const NOT_CANDIDATE_USER = 1022;
export const MULTIPLE_FISCAL_CODE = 1023;
export const FISCAL_CODE_NOT_FOUND = 1024;
export const CANDIDATURA_INOLTRATA_ERROR = 1027;
export const RICHIESTA_ENTE_PAGATA_ERROR = 1028;

// Files
export const WRONG_BASE64_FORMAT_ERROR = 1100;
export const FAILED_TO_SAVE_BASE64_FILE_ERROR = 1101;
export const OVERRIDE_FILE_ERROR = 1102;
export const FAILED_TO_DELETE_FILE_ERROR = 1103;

// Associazione domanda lavoratore
export const ASSOCIA_LAVORATORE_ERROR = 2004;

// Token Service Validation
export const TOKEN_SERVICE_DATA_NOT_VALID = 2010;
export const TOKEN_SERVICE_EXPIRED = 2009;

// Spazi WeMi
export const SPAZI_WEMI_ID_ERROR = 2013;

// Content errors
export const CONTENT_CODE_INVALID = 2016;

// Voucher ERRORS
export const ANNULLA_VOUCHER_ERROR = 2098;
export const RIPRISTINO_VOUCHER_ERROR = 2099;
export const INSERT_TRANSACTION_ERROR = 3000;
export const TIMESTAMP_VOUCHER_ERROR = 3001;
