
export const keyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
  ESC: 27,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  RIGHT_ARROW: 39,
  LEFT_ARROW: 37,
  DELETE: 46,
  RIGHT_ARROW: 39,
  LEFT_ARROW: 37,
};
