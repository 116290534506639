import { PAGE_HOME_URL, PAGE_INCLUSIONE_APPRENDIMENTO_ITALIANO, PAGE_HOME_INCLUSIONE } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi inclusione',
    url: PAGE_HOME_INCLUSIONE,
  },
  {
    slash: 'APPRENDIMENTO DELLA LINGUA ITALIANA',
    url: PAGE_INCLUSIONE_APPRENDIMENTO_ITALIANO,
  },
];
