const ATTRIBUTI_CONTATTI = {
        FG_CONTATTO_EQ_RICHIEDENTE: 36,
        TX_COGNOME_CONTATTO: 81,
        TX_NOME_CONTATTO: 90,
        CD_SESSO_CONTATTO: 20,
        DT_NASCITA_CONTATTO: 32,
        TX_LUOGO_DI_NASCITA_CONTATTO: 87,
        CD_STATO_DI_NASCITA_CONTATTO: 21,
        TX_CODICE_FISCALE_CONTATTO: 79,
        TX_TELEFONO_CONTATTO: 94,
        TX_EMAIL_CONTATTO: 84,
        FG_SEDE_CONTATTO_EQ_RESIDENZA: 55,
        TX_INDIRIZZO_SEDE_DI_LAVORO: 86,
        TX_COMUNE_SEDE_DI_LAVORO: 82,
        CD_MUNICIPIO_SEDE_DI_LAVORO: 11,
        FIX_CAP: 99,
}

export default ATTRIBUTI_CONTATTI;