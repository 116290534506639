/** @format */

const FooterTranslations = {
  en: {
    'Footer.municipality': 'Municipality',
    'Footer.link.municipality1': 'Palazzo Marino',
    'Footer.link.municipality2': 'Town Halls',
    'Footer.link.municipality3': 'Transparent administration',
    'Footer.link.municipality4': 'Praetorian register',
    'Footer.link.municipality5': 'Statute, regulations and patronages',
    'Footer.link.municipality6': 'Municipal registers',
    'Footer.link.municipality7': 'General protocol and Town Hall',
    'Footer.link.municipality8': 'Cittadella degli archivi',
    'Footer.link.municipality9': 'Access to documents',
    'Footer.usefulLink': 'Useful Links',
    'Footer.usefulLink.link1': 'Lombardy region',
    'Footer.usefulLink.link2': 'Metropolitan City',
    'Footer.usefulLink.link3': 'Milan Ats',
    'Footer.usefulLink.link4': 'ATM',
    'Footer.contacts1': 'Piazza della Scala, 2 - 20121 Milan Italy',
    'Footer.contacts2': 'Tax Code/VAT number 01199250158',
    'Footer.contacts3': 'Single switchboard 020202',
  },
  it: {
    'Footer.municipality': 'Comune',
    'Footer.link.municipality1': 'Palazzo Marino',
    'Footer.link.municipality2': 'Municipi',
    'Footer.link.municipality3': 'Amministrazione trasparente',
    'Footer.link.municipality4': 'Albo pretorio',
    'Footer.link.municipality5': 'Statuto, regolamenti e patrocini',
    'Footer.link.municipality6': 'Albi comunali',
    'Footer.link.municipality7': 'Protocollo generale e Casa comunale',
    'Footer.link.municipality8': 'Cittadella degli archivi',
    'Footer.link.municipality9': 'Accesso agli atti',
    'Footer.usefulLink': 'Link utili',
    'Footer.usefulLink.link1': 'Regione Lombardia',
    'Footer.usefulLink.link2': 'Città Metropolitana',
    'Footer.usefulLink.link3': 'Aats Milano',
    'Footer.usefulLink.link4': 'ATM',
    'Footer.contacts1': 'Piazza della Scala, 2 - 20121 Milano Italia',
    'Footer.contacts2': 'Codice fiscale/Partita IVA 01199250158',
    'Footer.contacts3': 'Centralino unico 020202',
  },
};

export default FooterTranslations;
