
export const EstraiStatoNascia= [
  ``,
  ` { 
    EstraiStatoNascita{
        cdDominioTcb
        tlValoreTestuale
       }
   }`,
   `EstraiStatoNascita`
]

export const EstraiEtaLavoratore= [
  ``,
  `{
      EstraiEtaLavoratore{
        cdDominioTcb
        tlValoreTestuale
      }
    }`,
   `EstraiEtaLavoratore`
]

export const EstraiCarattereLavoratore= [
  ``,
  `{
      EstraiCarattereLavoratore{
        cdDominioTcb
        tlValoreTestuale
      }
    }`,
   `EstraiCarattereLavoratore`
]


