export const stateItems = [
  {
    id: undefined,
    value: "Tutti gli stati",
  },
  {
    id: ["0"],
    value: "Bozza",
  },
  {
    id: ["1", "6"],
    value: "Aperta - In gestione",
  },
  {
    id: ["2", "8"],
    value: "Chiusa - Finalizzata",
  },
  {
    id: ["3", "7"],
    value: "Chiusa - Annullata",
  },
  {
    id: ["4"],
    value: "Chiusa - Scaduta",
  },
  {
    id: ["5"],
    value: "Aperta - Inoltrata",
  },
  {
    id: ["10"],
    value: "Chiusa - Pagamento annullato",
  },
];

export const typeItems = [
  {
    id: 0,
    value: "Tutte le tipologie",
  },
  {
    id: 1,
    value: "Individuale",
  },
  {
    id: 2,
    value: "Condiviso",
  },
];
