import React from 'react';
import Text from 'components/ui/Text';
import AnchorLink from 'components/ui/AnchorLink';
import { Row } from 'components/ui/Grid';
import bafPdf from 'assets/PDF/wemi_baf_famiglie.pdf';
import { List } from 'components/ui2/List';

const fileBaf = {
  download: bafPdf,
  downloadName: 'wemi_baf_famiglie',
};

export const domandeFrequentiCittadino = [
  {
    domanda: "CHI SONO GLI ASSISTENTI FAMILIARI?",
    risposta:
      "Sono persone che possiedono le competenze necessarie per supportare la famiglia che si trova a vivere una situazione di bisogno. Le si affiancano nella gestione della casa o nella cura di una persona cara, stabilendo una relazione lavorativa di fiducia. I partners WeMi selezionano attentamente l'assistente familiare più adatto alle\ntue esigenze, incontrandolo/a a colloquio e valutando le sue esperienze pregresse, le competenze professionali e gli aspetti caratteriali.",
  },
  {
    domanda: "COME VENGONO SELEZIONATI I PROFILI",
    risposta:
      "Dopo l'invio della candidatura, i lavoratori sono chiamati a sostenere un colloquio. Tutti i lavoratori che vengono presentati alle famiglie sono stati valutati dagli operatori del servizio, che ne hanno verificato documenti, referenze, livello di competenze, disponibilità, capacità ed esperienze.",
  },
  {
    domanda: "POSSO USUFRUIRE DI SOSTEGNI ECONOMICI?",
    risposta: (
      <>
        <Text value="Regione Lombardia eroga per il 2023 un Bonus Assistenza Familiare (BAF), destinato alle famiglie che, per la cura di un anziano non autosufficiente, si avvalgono di un assistente familiare iscritto ai registri territoriali. Il buono non può superare il 60% delle spese sostenute per la retribuzione dell'assistente familiare e si prevede il riconoscimento di un tetto massimo di 2.400,00€ per ISEE uguali o inferiori ai 25.000,00€ e di 2.000,00€ per ISEE compresi tra i 25.000,00€ e i 35.000,00€. Per usufruirne è necessario compilare la domanda online sul portale di Regione Lombardia." />
        <Row fluid margin="1em 0 0">
          <Text value="Per maggiori informazioni," />
          &nbsp;
          <AnchorLink
            _blank
            value="scarica l'informativa"
            download={fileBaf.download}
            downloadName={fileBaf.downloadName}
          />
          <Text value="." />
        </Row>
        <Row fluid margin="1em 0 0">
          <Text value="La Misura B2, istituita da Regione Lombardia e finanziata dal Fondo nazionale per la non autosufficienza (FNA) e da fondi regionali, ha l'obiettivo di mantenere le persone con disabilità e le persone anziane non autosufficienti nel loro contesto di vita, offrendo un supporto al caregiver familiare o a personale di assistenza regolarmente assunto. I destinatari sono adulti, anziani e minori, al domicilio, in situazione di gravità così come accertata dall'art. 3 comma 3. della L. 104/1992 o beneficiari dell'indennità di accompagnamento." />
        </Row>
        <Row fluid margin="1em 0 0">
          <Text value="I residenti nel Comune di Milano devono inoltre avere i seguenti valori massimi ISEE in corso di validità di riferimento:" />
        </Row>
        <List>
          <div>
            <Text value="In caso di richiedente maggiorenne," tag="span" fontWeight="bold" />
            &nbsp;
            <Text
              value="la Persona con disabilità grave/non autosufficiente deve essere in possesso di un'attestazione ISEE sociosanitario (del proprio nucleo familiare o ristretto) inferiore o uguale ad € 25.000,00 (oppure inferiore o uguale ad € 30.000,00 nel caso di richiesta di Buono n. 3 “Buono sociale mensile fino ad un importo massimo mensile di euro € 800,00 alle persone con disabilità grave, che intendono realizzare il proprio progetto di vita indipendente senza il supporto del caregiver familiare, ma con l'ausilio di un assistente personale, autonomamente scelto e regolarmente impiegato e in presenza dei requisiti ministeriali richiesti dal Pro.Vi.” per persone dai 18 ai 65 anni di età)"
              tag="span"
            />
          </div>
          <div>
            <Text value="In caso di richiedente minorenne," tag="span" fontWeight="bold" />
            &nbsp;
            <Text
              value="la persona con disabilità grave/non autosufficiente deve essere in possesso di un'attestazione ISEE ORDINARIO del proprio nucleo familiare inferiore ad € 40.000"
              tag="span"
            />
          </div>
        </List>
        <Row fluid margin="1em 0 0">
          <Text value="Questa misura prevede l'erogazione di voucher dei seguenti buoni sociali: Buono sociale mensile di € 400, finalizzato a compensare le prestazioni di assistenza assicurate dal caregiver familiare" />
        </Row>
        <List type="space-counter">
          <Text
            value="Buono sociale mensile di € 400, finalizzato a compensare le prestazioni di assistenza assicurate dal caregiver familiare"
            tag="div"
          />
          <Text
            value="Buono sociale mensile fino a un importo massimo di € 800, finalizzato a sostenere a titolo di rimborso le spese del personale di assistenza regolarmente impiegato"
            tag="div"
          />
          <Text
            value="Buono Sociale per Progetto di Vita indipendente: alle persone con disabilità grave, che intendono realizzare il proprio progetto di vita indipendente senza il supporto del caregiver familiare, ma con l'ausilio di un assistente personale, autonomamente scelto e regolarmente impiegato e in presenza dei requisiti ministeriali richiesti dal Pro. Vi, può essere riconosciuto un buono sociale mensile fino a un massimo di € 800. Tale buono fino a € 800,00/mese potrà essere erogato solo in presenza di una progettualità di vita indipendente condivisa tra Ambito e ASST e può essere riconosciuto alle persone che sono in possesso di un ISEE sociosanitario (o del nucleo ristretto) minore o uguale (≤) a € 30.000"
            tag="div"
          />
          <Text
            value="Voucher sociale per sostenere la vita di relazione di minori con disabilità con appositi progetti di natura educativa/socializzante che favoriscano il loro benessere psicofisico (es. pet therapy, attività motoria in acqua, frequenza a centri estivi, ecc.) fino a un massimo di € 600 su base mensile"
            tag="div"
          />
          <Text
            value="Voucher sociale per interventi che favoriscano il benessere psicofisico e sostengano la vita di relazione di adulti e anziani con disabilità con appositi progetti di natura socializzante e/o di supporto al caregiver fino a un massimo di € 600 mensili"
            tag="div"
          />
        </List>
        <Row fluid margin="1em 0 0">
          <Text value="I cittadini residenti nel Comune di Milano possono inoltrare la propria richiesta online collegandosi alla pagina dedicata sul sito" />
          &nbsp;
          <AnchorLink
            weight="bold"
            value="https://www.comune.milano.it/servizi/misura-b2"
            to="https://www.comune.milano.it/servizi/misura-b2"
            textDecoration="none"
          />
          &nbsp;
          <Text value="nel periodo in cui è attivo il bando." />
        </Row>
        <Row fluid margin="1em 0 0" direction="column">
          <Text
            tag="p"
            value="Tutti i buoni sociali saranno erogati esclusivamente a seguito di parere favorevole dell'Unità di Valutazione Multidimensionale (UVM) e definizione del Progetto Individuale (PAI) salvo per le persone in continuità nel caso in cui sia già stata consegnata una Valutazione Multidimensionale da non più di 2 anni (quindi ultimo parere favorevole UVM ottenuto entro il 31/12/2020). "
          />
        </Row>
        <Row fluid margin="1em 0 0" direction="column">
          <Text tag="p" value="Per saperne di più:" />
        </Row>
        <Row fluid margin="1em 0 0" direction="column">
          <Text tag="p" value="Visita il sito del Comune di Milano:" />
          <AnchorLink
            _blank
            width="fit-content"
            value="Sostegni economici"
            to="https://www.comune.milano.it/aree-tematiche/servizi-sociali/sostegno-al-reddito"
          />
          <AnchorLink
            _blank
            width="fit-content"
            value="Misura B2"
            to="https://www.comune.milano.it/servizi/misura-b2"
          />
          <AnchorLink
            _blank
            width="fit-content"
            value="Visita il sito della Regione Lombardia"
            to="https://www.bandi.regione.lombardia.it/procedimenti/new/bandi/bandi/comunita-diritti/cittadinanza-attiva/bonus-assistenti-familiari-nuovo-avviso-RLD12022025963"
          />
        </Row>
      </>
    ),
  },
  {
    domanda: "POSSO USUFRUIRE DI DETRAZIONI FISCALI?",
    risposta: (
      <>
        <Text
          tag="p"
          value={`Possono usufruirne le persone assistite o i familiari che sostengono la spesa, se il reddito familiare complessivo è inferiore a 40.000 euro. Le agevolazioni fiscali sono le seguenti:
                Detrazione dei contributi versati, per un importo massimo di 1.549,37 euro l'anno (con l'esclusione della quota a carico del lavoratore); 
                Detrazione dall'imposta lorda del 19% delle spese sostenute, per un importo massimo di 2.100 euro all'anno (solo in caso di assunzione di badante).  
             `}
        />
        <Text value="Per maggiori informazioni, si consiglia di" />
        &nbsp;
        <AnchorLink
          _blank
          value="visitare il sito dell'INPS."
          to="https://www.inps.it/nuovoportaleinps/default.aspx?itemdir=45740"
        />
      </>
    ),
  },
  {
    domanda: "QUANTO COSTA",
    risposta:
      "Il costo del servizio di selezione dipende dal tariffario dei partners. Il costo dell'assistente familiare (badante, colf, baby sitter) varia a seconda delle ore settimanali lavorate e delle mansioni del lavoratore. Le tariffe partono dai minimi sindacali stabiliti\ndal Contratto Collettivo Nazionale sulla Disciplina del Lavoro Domestico per i contratti diretti tra famiglia e assistente familiare. Nel caso di prestazioni\ndi lavoro occasionale è possibile regolarizzare la posizione del lavoratore attraverso l'apertura di un Libretto Famiglia o chiedendo il servizio alle cooperative\nche gestiscono gli Spazi WeMi. Per avere informazioni, consulenza, accompagnamento e supporto nell' individuare le migliori soluzioni per sostenere i costi\ndell'assistenza e della cura a partire dalle risorse a tua disposizione e da eventuali forme di sostegno a cui puoi accedere, è possibile prenotare un colloquio\ngratuito con i nostri consulenti di educazione finanziaria di qualità.",
  },
  {
    domanda: "COME ASSUMERE?",
    risposta: (
      <>
        <Text value="L'assunzione può avvenire secondo tre diverse modalità:" />
        <div style={{ margin: "1em 0 0" }}>
          <Text weight="bold" value="Assunzione diretta" />
          <Text value=": il datore di lavoro e il lavoratore sottoscrivono un contratto di lavoro, che comporta diritti e doveri per entrambi, elencati nella lettera di assunzione. Il rapporto di lavoro per essere effettivo deve essere comunicato all'INPS tramite contact center o procedura telematica disponibile sul sito dell'INPS." />
        </div>
        <div>
          <Text weight="bold" value="Assunzione tramite libretto di famiglia" />
          <Text value=": questa modalità è utilizzata per regolarizzare un assistente familiare che svolge prestazioni di lavoro occasionale, per un massimo di 280 ore annue. Esso è composto da titoli di pagamento del valore di 10 euro all'ora per un importo che non deve superare i 5.000 euro all'anno. Per accedervi è necessario che sia il datore di lavoro sia il lavoratore siano registrati sul portale dell'INPS." />
        </div>
      </>
    ),
  },
  {
    domanda: "Cos'è la certificazione di qualità UNI 11766:2019 per l'assistente familiare?",
    risposta: (
      <>
        <Text value="È una sorta di patentino di qualità, regolato secondo la norma UNI 11766:2019, in vigore dal 12 dicembre 2019. Per ottenerlo occorre che l'assistente familiare possieda tre prerequisiti:" />
        <List>
          <Text
            value="Essere stata impiegata regolarmente presso un datore di lavoro domestico per almeno dodici mesi, anche non continuativi, durante i tre anni precedenti;"
            tag="div"
          />
          <Text value="Possedere una conoscenza basilare della lingua italiana;" tag="div" />
          <Text
            value="Aver frequentato un corso di formazione (almeno 40 ore per le colf e 64 ore per baby-sitter e badanti)."
            tag="div"
          />
        </List>
        <div style={{ margin: "1em 0 0 0" }}>
          <Text
            tag="div"
            value="Per conseguire la certificazione, occorre inoltre che le assistenti familiari posseggano una serie di competenze, che variano a seconda dei profili. Tutte le figure sono tenute a sottoscrivere e rispettare il codice di comportamento."
          />
          <Text
            tag="div"
            value="La certificazione non è obbligatoria per poter svolgere la professione, ma rappresenta sicuramente una fonte di garanzia per permettere alle famiglie di affidare a terzi i propri cari in maggiore sicurezza."
          />
          <Text
            tag="div"
            value="Decorsi 12 mesi dalla data di decorrenza del CCNL sottoscritto a settembre 2020, ai lavoratori inquadrati nei livelli B, Bs, Cs e Ds in possesso di tale certificazione in corso di validità, verrà riconosciuta una indennità mensile, assorbibile da eventuali superminimi individuali di miglior favore percepiti dal lavoratore. Per i lavoratori conviventi con profilo D super) tale indennità sarà assorbita da quella di funzione."
          />
        </div>
      </>
    ),
  },
];
