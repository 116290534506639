
export const HOME_ANCHORS = Object.freeze({
  scopriServizi: 'scopri-i-servizi',
  lavoraConNoi: 'lavora-con-noi',
  qualiVantaggi: 'quali-vantaggi',
  comeContattarci: 'come-contattarci',
  sistemaWeMi: 'contatori',
  comeFunziona: 'come-funziona',
  whatsThis: 'cosa-e',
  percheWeMi: 'perche-wemi',
});
