/**
 * The label to display when selected the filter
 */
export const labelAltriValori = {
  municipio: 'Municipi',
  genere: 'Genere Utente',
  superficie: 'MQ Casa',
  oraSettimanale: 'Ore settimanali',
  orarioLavoro: 'Orario Lavoro',
  tipologiaContratto: 'Tipologia Contratto',
  maxBambini: 'Max Bambini',
  votoOperatore: 'Voto Operatore',
};
