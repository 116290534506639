import Consulenza1 from 'images2/inclusione/consulenza1.png';
import Consulenza2 from 'images2/inclusione/consulenza2.png';
import Consulenza3 from 'images2/inclusione/consulenza3.png';

export const stepList = [
  {
    title: 'CONTATTO',
    img: Consulenza1,
    tag: 'contatto',
  },
  {
    title: 'INFORMAZIONE',
    img: Consulenza2,
    tag: 'informazione',
  },
  {
    title: 'SUPPORTO',
    img: Consulenza3,
    tag: 'supporto',
  },
];
