/** @format */

export const tipologiaContenutoAll = () => [
  '',
  `{
          tipologiaContenutoAll {
            id
            ty_contenuto
          }
        }`,
];