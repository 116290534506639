export const NUMBER_ITEMS_TABLE = 5;

export const columnsTable = [
  '',
  'CODICE VOUCHER',
  'DATA FINE VALIDITÀ',
  'CODICE FISCALE ASSEGNATARIO',
  'RESIDUO',
  'AZIONI',
];
