export const steps = [
  {
    title: 'Scegli il servizio',
    color: 'primary',
    link: null,
    active: true,
    onClickStepHandler: () => null,
  },
  {
    title: 'Seleziona gli enti',
    color: 'orange',
    link: null,
    active: false,
    onClickStepHandler: () => null,
  },
  {
    title: 'Richiedi il servizio',
    color: 'green',
    link: null,
    active: false,
    onClickStepHandler: () => null,
  }
];