import { PAGE_HOME_INCLUSIONE, PAGE_CONSULENZA_SOCIALE_GIURIDICA, PAGE_HOME_URL } from 'types/url';

export const BreadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi inclusione',
    url: PAGE_HOME_INCLUSIONE,
  },
  {
    slash: 'CONSULENZA SOCIALE E GIURIDICA',
    url: PAGE_CONSULENZA_SOCIALE_GIURIDICA,
  },
];
