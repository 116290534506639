/** @format */
export const ADD_FORMDATA = 'ADD_FORMDATA';
export const GET_BROWSER_INFO = 'GET_BROWSER_INFO';

export const ADD_ARRAY = 'ADD_ARRAY';
export const REMOVE_ARRAY = 'REMOVE_ARRAY';

export const SET_REFERRER = 'SET_REFERRER';

export const SET_ROUTE_PATH = 'SET_ROUTE_PATH';

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const ADD_PARAMETER_GOI003 = 'ADD_PARAMETER_GOI003';

export const OPEN_MODAL_INSERIMENTO = 'OPEN_MODAL_INSERIMENTO';


export const TCB_ADD_PARAMETER_MENU_NAVIGAZIONE = 'TCB_ADD_PARAMETER_MENU_NAVIGAZIONE';
export const ADD_PARAMETER_GOI005 = 'ADD_PARAMETER_GOI005';


export const CREATE_LOGIN_REQUEST = 'CREATE_LOGIN_REQUEST';
export const CREATE_LOGIN_FAILURE = 'CREATE_LOGIN_FAILURE';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const SEARCH_INPUT = 'SEARCH_INPUT';

export const CREATE_LOGOUT_REQUEST = 'CREATE_LOGOUT_REQUEST';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SCROLL_INTO_VIEW = 'SCROLL_INTO_VIEW';


export const GRAPHQL_REQUEST = 'GRAPHQL_REQUEST';
export const GRAPHQL_SUCCESS = 'GRAPHQL_SUCCESS';
export const GRAPHQL_FAILURE = 'GRAPHQL_FAILURE';
export const OVERLAY_SPINNER = 'OVERLAY_SPINNER';
export const ENTE_ADD = 'ENTE_ADD';
export const FILTER_ADD = 'FILTER_ADD';
export const RESET_FIELD = 'RESET_FIELD';
export const RESET_FIELDS = 'RESET_FIELDS';
export const FILTER_REMOVE = 'FILTER_REMOVE';
export const FILTER_DATA_ADD = 'FILTER_DATA_ADD';
export const ENTE_REMOVE = 'ENTE_REMOVE';
export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const CURRENT_REQUEST = 'CURRENT_REQUEST';
export const ENT_ERI_ACCETTAZIONE = 'ENT_ERI_ACCETTAZIONE';
export const CATEGORIA_SELEZIONATA = 'CATEGORIA_SELEZIONATA';
export const DATI_FATTURAZIONE = 'DATI_FATTURAZIONE';
export const CAT_HOMEPAGE = 'CAT_HOMEPAGE';
export const TCB_PREV_LIGHT = 'TCB_PREV_LIGHT';
export const TCB_CONFIG_001 = 'TCB_CONFIG_001';
export const TCB_CONFIG_002 = 'TCB_CONFIG_002';
export const TCB_CONFIG_003 = 'TCB_CONFIG_003';
export const TCB_CONFIG_004 = 'TCB_CONFIG_004';
export const TCB_CONFIG_005 = 'TCB_CONFIG_005';
export const TCB_CONFIG_006 = 'TCB_CONFIG_006';
export const TCB_CONFIG_008 = 'TCB_CONFIG_008';
export const TCB_REQUIRED = 'TCB_REQUIRED';
export const TCB_STEPPER = 'TCB_STEPPER';
export const TCB_STEPPER_2 = 'TCB_STEPPER_2';
export const TCB_STEPPER_2_INFO = 'TCB_STEPPER_2_INFO';
export const TCB_ICL_001 = 'TCB_ICL_001';
export const TCB_ICL_006 = 'TCB_ICL_006';
export const FILTER_INDEX_ADD = 'FILTER_INDEX_ADD';
export const ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR';
export const ADD_PURCHASE = 'ADD_PURCHASE';
export const REMOVE_PURCHASE = 'REMOVE_PURCHASE';
export const SUCCESS_LOG = 'SUCCESS_LOG';
export const FAILURE_LOG = 'FAILURE_LOG';
export const REQUEST_LOG = 'REQUEST_LOG';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const CALCOLA_SIM = 'CALCOLA_SIM';
export const APPLICA_PARAMETRI = 'APPLICA_PARAMETRI';
export const TCB_DISP_CONFIG = 'TCB_DISP_CONFIG';
export const PRINT_ENABLED = 'PRINT_ENABLED';
export const ADD_FILTER_ENT_ERI = 'ADD_FILTER_ENT_ERI';
// NON NECESSARIE DA ELIMINARE
export const COMUNE_MILANO_REST_REQUEST = 'COMUNE_MILANO_REST_REQUEST';
export const COMUNE_MILANO_REST_SUCCESS = 'COMUNE_MILANO_REST_SUCCESS';
export const COMUNE_MILANO_REST_FAILURE = 'COMUNE_MILANO_REST_FAILURE';
export const COMUNE_MILANO_SOAP_REQUEST = 'COMUNE_MILANO_SOAP_REQUEST';
export const COMUNE_MILANO_SOAP_SUCCESS = 'COMUNE_MILANO_SOAP_SUCCESS';
export const COMUNE_MILANO_SOAP_FAILURE = 'COMUNE_MILANO_SOAP_FAILURE';

export const WEMI_ERROR = 'WEMI_ERROR';

export const FORWARD_SELECTED_ENTI = 'FORWARD_SELECTED_ENTI';
export const REMOVE_SELECTED_ENTI = 'REMOVE_SELECTED_ENTI';
export const ADD_LISTINI_PREZZI = 'ADD_LISTINI_PREZZI';
export const CALCOLA_PREZZO = 'CALCOLA_PREZZO';
