
export const getMaxOrderTextualValuesSlider = [
  '',
  `query getMaxOrderTextualValuesSlider {
    getMaxOrderTextualValuesSlider{
         title
         description
         order
       }
   }
   `,
  'getMaxOrderTextualValuesSlider'];
