import { PAGE_EDUCAZIONE_FINANZIARIA, PAGE_HOME_URL } from 'types/url';

export const breadcrumbPath = [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi Educazione Finanziaria',
    url: PAGE_EDUCAZIONE_FINANZIARIA,
  },
  {
    slash: 'DEBITI',
    url: '',
  },
];
