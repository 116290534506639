export const codiciAttributo = {
  CALENDARIO_ASSISTENZA_NOTTURNA: 1,
  CALENDARIO_CONVIVENZA_RIDOTTA: 2,
  CALENDARIO_NON_CONVIVENTE: 3,
  CALENDARIO_PRESENZA_NOTTURNA: 4,
  CD_ALTEZZA_BENEFICIARIO: 5,
  CD_CORPORATURA_BENEFICIARIO: 6,
  CD_DEAMBULAZIONE_BENEFICIARIO: 7,
  CD_FASCIA_ETA_BENEF_BAMBINO: 8,
  CD_FASCIA_ORE_SETTIMANALI: 9,
  CD_INFO_CASA: 10,
  CD_MUNICIPIO_SEDE_DI_LAVORO: 11,
  CD_NUMERO_BAGNI_CASA: 12,
  CD_NUMERO_STANZE_CASA: 13,
  CD_ORARIO_LAVORO: 14,
  CD_REL_RICH_BENEFICIARIO: 15,
  CD_RIC_FASCIA_ETA_LAV: 16,
  CD_RIC_SESSO_LAV: 17,
  CD_RIC_TITOLO_STUDIO_GENERALE: 18,
  CD_SESSO_BENEFICIARIO: 19,
  CD_SESSO_CONTATTO: 20,
  CD_STATO_DI_NASCITA_CONTATTO: 21,
  CD_STIPENDIO_A_CONVIVENZA_RIDOTTA: 22,
  CD_STIPENDIO_ASSISTENZA_NOTTURNA: 23,
  CD_STIPENDIO_CONVIVENTE: 24,
  CD_STIPENDIO_NON_CONVIVENTE: 25,
  CD_STIPENDIO_PRESENZA_NOTTURNA: 26,
  CD_STIPENDIO_WEEKEND: 27,
  CD_SUPERFICIE_CASA: 28,
  CD_TIPOLOGIA_CONTRATTO: 29,
  DT_DATA_CONTRATTO_A: 30,
  DT_DATA_CONTRATTO_DA: 31,
  DT_NASCITA_CONTATTO: 32,
  FG_ALTRE_ESPERIENZE_COLLAB: 33,
  FG_BAMBINO_PARLA_ITALIANO: 34,
  FG_BENEFICIARIO_EQ_RICHIEDENTE: 35,
  FG_CONTATTO_EQ_RICHIEDENTE: 36,
  FG_DISP_TRASFERTE_BREVI: 37,
  FG_DISP_TRASFERTE_LUNGHE: 38,
  FG_DISPONIBILITA_STRAORDINARI: 39,
  FG_DISPONIBILITA_VACANZA_CON_FAMIGLIA: 40,
  FG_DISPONIBILITA_VACANZA_SOLO_CON_ASSISTITO: 41,
  FG_PREF_AUTOMUNITA: 42,
  FG_PREF_MUNITA_PATENTE: 43,
  FG_PRESEMZA_FUMATORI: 44,
  FG_PRESENZA_ALTRI_ANIMALI: 45,
  FG_PRESENZA_ALTRI_FRATELLI: 46,
  FG_PRESENZA_ALTRI_PARENTI: 47,
  FG_PRESENZA_ANIMALI: 48,
  FG_PRESENZA_ASCENSORE_CASA: 49,
  FG_PRESENZA_GIARDINO_CASA: 50,
  FG_PRESENZA_NONNI: 51,
  FG_PRESENZA_TERRAZZA_CASA: 52,
  FG_RIC_BADANTE_ISCRITTA_REGIONE: 53,
  FG_RICHIESTE_MANSIONI_COLF: 54,
  FG_SEDE_CONTATTO_EQ_RESIDENZA: 55,
  LS_ESPERIENZE_PREGRESSE_PATOLOGIE: 57,
  LS_LINGUE_PARLATE_BAMBINO: 58,
  LS_MANSIONI_RICHIESTE_ANIMALI: 59,
  LS_MANSIONI_RICHIESTE_BADANTE: 60,
  LS_MANSIONI_RICHIESTE_COLF: 61,
  LS_MANSIONI_RICHIESTE_TATA: 62,
  // LS_MEZZA_GIORNATA_CONVIVENTE: 63, // Duplicated!!! Why???
  LS_PATOLOGIE_BENEF: 64,
  LS_PATOLOGIE_BENEF_BADANTE: 65,
  LS_PATOLOGIE_BENEF_TATA: 66,
  LS_PREF_LINGUE: 67,
  LS_RIC_CARATTERE_LAV: 68,
  // LS_SPAZI_CONVIVENTE: 69, // Duplicated!!! Why???
  NR_ANNI_DI_ESPERIENZA: 70,
  NR_ETA_BENEFICIARIO: 71,
  NR_ORE_RICHIESTE: 72,
  NR_PERSONE_ASSISITITE: 73,
  NR_PRESENZA_CANI: 74,
  NR_PRESENZA_GATTI: 75,
  POSITIVE_ESPERIENZE_COLLAB: 76,
  TX_ALTRE_INFO_BENEFICIARIO: 77,
  TX_ALTRE_INFO_PATOLOGIE: 78,
  TX_CODICE_FISCALE_CONTATTO: 79,
  TX_COGNOME_BENEFICIARIO: 80,
  TX_COGNOME_CONTATTO: 81,
  TX_COMUNE_SEDE_DI_LAVORO: 82,
  TX_DETTAGLIO_ANIMALI: 83,
  TX_EMAIL_CONTATTO: 84,
  TX_GIORNATA_TIPO_BENEFICIARIO: 85,
  TX_INDIRIZZO_SEDE_DI_LAVORO: 86,
  TX_LUOGO_DI_NASCITA_CONTATTO: 87,
  TX_MOTVO_CHIUSURA_DOMANDA: 88,
  TX_NOME_BENEFICIARIO: 89,
  TX_NOME_CONTATTO: 90,
  TX_NOTE_SU_CONTRATTO: 91,
  TX_PIANO_CASA: 92,
  TX_PREF_ALTRO: 93,
  TX_TELEFONO_CONTATTO: 94,
  TX_PATOLOGIE_BENEFICIARIO: 95,
  CD_LIVELLO_CONTRATTUALE: 96,
  CD_MOTVO_CHIUSURA_DOMANDA: 97,
  CD_TIPOLOGIA_ASSUNZIONE: 98,
  FIX_CAP: 99,
  CD_ALTEZZA: 100,
  CD_CITTADINANZA_UTENTE: 101,
  CD_CORPORATURA: 102,
  CD_FASCIA_ETA_UTENTE: 103,
  CD_MUNICIPIO_DOMICILIO: 104,
  CD_MUNICIPIO_RESIDENZA: 105,
  CD_RIC_SESSO_ASSISTITO: 106,
  CD_SESSO_UTENTE: 107,
  CD_STATO_DI_NASCITA_UTENTE: 108,
  CD_STATO_OCCUPAZIONALE: 109,
  DT_NASCITA_UTENTE: 110,
  DT_DATA_RICHIESTA_RINNOVO_PS: 111,
  DT_DATA_RILASCIO_PASSAPORTO: 112,
  DT_DATA_SCADENZ_PASSAPORTO: 113,
  DT_DISPONIBILE_DA: 114,
  DT_RILASCIO_CARTA_DI_IDENTITA: 115,
  DT_SCADENZA_CARTA_DI_IDENTITA: 116,
  FASCE_ETA_MANSIONI_TATA: 117,
  FG_ACCETTA_VACANZE_FAMIGLIA: 118,
  FG_ALLERGIA_CANI: 119,
  FG_ALLERGIA_GATTI: 120,
  FG_AUTOMUNITO: 121,
  FG_DISP_DIFFERENTE_PER_SERVIZIO: 122,
  FG_DISP_LAV_CASA_CON_ANIMALI: 123,
  FG_DISP_LAV_COPPIE_ANZ: 124,
  FG_DISP_LAV_FUORI_MILANO: 125,
  FG_DISP_LAV_PERS_ANZIANA_FAMIGLIA: 126,
  FG_DISP_MANSIONI_COLF: 127,
  FG_DISP_SOST_BREVI: 128,
  FG_DISP_SOST_LUNGHE: 129,
  FG_DISP_SVEGLIARSI_NOTTE: 130,
  FG_DISPONIBILE_A_GUIDARE_PER_LAVORO: 131,
  FG_FAMIGLIE_NUMEROSE: 132,
  FG_FUMATORE: 133,
  FG_IDONEA: 134,
  FG_INTERESSE_A_FREQUENTARE_CORSI_DI_ITALIANO: 135,
  FG_INTERESSE_A_FREQUENTARE_CORSI_SA: 136,
  FG_INTOLLERANZA_ALLERGIA__CIBO: 137,
  FG_ISCRITTO_INPS: 138,
  FG_ISCRITTO_REGIONE_LOMB: 139,
  FG_PATENTE_DI_GUIDA_AUTO: 140,
  FG_RESIDENZA_EQ_DOMICILIO: 141,
  FG_RICHIESTA_RINNOVO_PS: 142,
  IMG_FOTO: 143,
  LIV_CAPACITA_COMUNICATIVE: 144,
  LIV_CAPACITA_DI_ADATTAMENTO: 145,
  LIV_CAPACITA_DI_GESTIONE_DEL_TEMPO: 146,
  LIV_CONOSCENZA_ITALIANO: 147,
  LIV_LINGUE_CONOSCIUTE: 148,
  LS_CARATTERE: 149,
  LS_CORSI_BADANTE: 150,
  LS_CORSI_TATA: 151,
  LS_DISPONIBILITA_SUPERFICI_CASA: 152,
  LS_ESPERIENZE_PREGRESSE_PATOLOGIE_GENERICHE: 153,
  LS_FASCE_ORE_SETTIMANALI: 154,
  LS_FASCIA_ETA_BAMBINI: 155,
  LS_INTERESSI: 156,
  LS_MANSIONI_BADANTE: 157,
  LS_MANSIONI_COLF: 158,
  LS_MEZZA_GIORNATA_CONVIVENTE: 159,
  LS_MUNICIPI_RIF_DISPONIBILITA: 160,
  LS_ORARIO_LAVORO: 161,
  LS_PATOLOGIE_DISP_ACCUDIMENTO: 162,
  LS_SPAZI_CONVIVENTE: 163,
  LS_SPAZI_CONVIVENZA_RIDOTTA: 164,
  LS_STIPENDIO_A_CONVIVENZA_RIDOTTA: 165,
  LS_STIPENDIO_ASSISTENZA_NOTTURNA: 166,
  LS_STIPENDIO_CONVIVENTE: 167,
  LS_STIPENDIO_NON_CONVIVENTE: 168,
  LS_STIPENDIO_PRESENZA_NOTTURNA: 169,
  LS_STIPENDIO_WEEKEND: 170,
  LS_TIPOLOGIA_CONTRATTO: 171,
  LS_TIPOLOGIA_SERVIZIO: 172,
  NR_ANNI_ESP_BADANTE: 173,
  NR_ANNI_ESP_COLF: 174,
  NR_ANNI_ESP_TATA: 175,
  NR_ETA_UTENTE: 176,
  NR_GIORNI_SCADENZA_PS: 177,
  NR_MAX_BAMBINI: 178,
  NR_MEDIA_COMPETENZE_RELAZIONALI: 179,
  NR_VOTO_BADANTE: 180,
  NR_VOTO_COLF: 181,
  NR_VOTO_TATA: 182,
  TX_ALLERGIA_ALTRO: 183,
  TX_CODICE_FISCALE: 184,
  TX_COGNOME_UTENTE: 185,
  TX_COMUNE_DI_RILASCIO_CARTA_DI_IDENTITA: 186,
  TX_COMUNE_DOMICILIO: 187,
  TX_COMUNE_RESIDENZA: 188,
  TX_DATA_RILASCIO_PS: 189,
  TX_DATA_SCADENZA_PS: 190,
  TX_EMAIL: 191,
  TX_ENTE_RILASCIO_PASSAPORTO: 192,
  TX_EVENTUALI_VINCOLI: 193,
  TX_INDIRIZZO_DOMICILIO: 194,
  TX_INDIRIZZO_RESIDENZA: 195,
  TX_LUOGO_DI_NASCITA_UTENTE: 196,
  TX_MOTIVO_PS: 197,
  TX_NOME_UTENTE: 198,
  TX_NOTA_OPERATORE: 199,
  TX_NR_CARTA_DI_IDENTITA: 200,
  TX_NR_PASSAPORTO: 201,
  TX_NR_PS: 202,
  TX_QUESTURA_RILASCIO_PS: 203,
  TX_RECAPITO_TELEFONICO: 204,
  LS_ESPERIENZE_PREGRESSE_PATOLOGIE_ANZIANI: 205,
  DT_RINNOVO_PASSAPORTO: 206,
  TX_PROVINCIA_SEDE_DI_LAVORO: 207,
  TX_ALLERGIA_ANIMALI_ALTRO: 208,
  TX_ESPERIENZE_ALTRE: 209,
  TX_RECAPITO_TELEFONICO_2: 211,
  DT_ITALIA_DAL: 210
};
