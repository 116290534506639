
export const comeContattarciOrari = [
  {
    day: 'Lunedì',
    from: '8',
    to: '18',
  },
  {
    day: 'Martedì',
    from: '8',
    to: '18',
  },
  {
    day: 'Mercoledì',
    from: '8',
    to: '18',
  },
  {
    day: 'Giovedì',
    from: '8',
    to: '18',
  },
  {
    day: 'Venerdì',
    from: '8',
    to: '18',
  },
  {
    day: 'Sabato',
    from: '8',
    to: '18',
  },
];
