/** @format */

const EntChatTranslations = {
    en: {
      'EntChat.header.title': 'Chat with ',
      'EntChat.header.breadcrumb': "Chat Page",
    },
    it: {
      'EntChat.header.title': 'Conversazione con ',
      'EntChat.header.breadcrumb': "Pagina conversazione",    
    },
  };
  
  export default EntChatTranslations;
  